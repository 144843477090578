import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/seo';
import BlogHero from '../components/blog-hero';
import { Container, Box, useTheme, Theme, Button, Typography } from '@material-ui/core';
import Section from '../components/section';
import BlogTagAndDate from '../components/blog-tag-and-date';
import kebabCase from 'lodash.kebabcase';
import BlogList from '../components/blog-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { BlogContentBox } from '../components/styledComponents';
import MainLayout from '../layouts/mainLayout';


export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 152)
      fields {
        slug
        tags: tag_slugs
      }
      frontmatter {
        publish_date(formatString: "MMMM D, YYYY")
        publishedYear: publish_date(formatString: "YYYY")
        published: publish_date(formatString: "YYYY-MM-DD")
        title
        tags
        primary_image {
            childImageSharp {
                fluid(quality: 90, maxWidth: 4160) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
        }
      }
    }
    allPosts: allMarkdownRemark (
        sort: { order: DESC, fields: [frontmatter___publish_date] }
        filter: {
          frontmatter: {
            published: { eq: true }
          }
        }
    ){
      edges {
        node {
          excerpt(pruneLength: 400)
          fields {
            slug
            tags: tag_slugs
          }
          frontmatter {
            title
            tags
            publish_date(formatString: "MMMM D, YYYY")
            primary_image {
                childImageSharp {
                    fluid(maxWidth: 350, maxHeight: 350, cropFocus: ENTROPY) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
          }
        }
      }
    }
  }`;

interface RelatedPosts {
  excerpt: string,
  fields: {
    slug: string
    tags: Array<string>
  },
  frontmatter: {
    title: string
    tags: Array<string>
    publish_date: string
    primary_image: import("gatsby-source-filesystem").FileSystemNode;
  }
}

const SimilarArticles = (allPosts: Array<RelatedPosts>, currentSlug: string, tags: Array<string>, maxArticles: number = 3) => {
  const articles = allPosts.filter(a => a.fields.slug != currentSlug);
  const postTags: Array<string> = tags.map(t => kebabCase(t));
  var identityMap: {
    [slug: string]: {
      post: RelatedPosts,
      points: number
    }
  } = {};

  articles.forEach(a => {

    if (!identityMap.hasOwnProperty(a.fields.slug)) {
      identityMap[a.fields.slug] = {
        post: a,
        points: 0
      }
    }

    var value = identityMap[a.fields.slug];
    const articleTags: Array<string> = a.frontmatter.tags.map(t => kebabCase(t));
      postTags.forEach(t => {
      if (articleTags.includes(t)) {
        value.points += 1;
      }
    });
  });

  var output = Object.keys(identityMap).map((slug) => identityMap[slug])
    .filter(a => a.points > 0)
    .sort((a, b) => (a.points > b.points) ? 1 : -1)
    .splice(0, maxArticles)
    .map(x => x.post);

  if (output.length < maxArticles) {
    const diff = maxArticles - output.length;
    const outputSlugs = output.map(x => x.fields.slug);
    const noDupes = articles.filter(a => !outputSlugs.includes(a.fields.slug));

    output = [...output, ...noDupes.splice(0, diff)];
  }

  return output;
}

const BlogPost = ({ data: { markdownRemark, allPosts }, location }) => {
  const { fields, frontmatter, html } = markdownRemark;
  const theme = useTheme<Theme>();

  const relatedPosts = SimilarArticles(allPosts.edges.map(e => e.node), fields.slug, frontmatter.tags);

  return (
    <MainLayout transparentNav>
      <SEO title={frontmatter.title} blogPost={markdownRemark} location={location} />
      <BlogHero title={frontmatter.title} image={frontmatter.primary_image} />
      <Container maxWidth="md">
        <Section tightTop tightBottom>
          <BlogContentBox theme={theme}>
            <BlogTagAndDate tagSlugs={fields.tags} tags={frontmatter.tags} date={frontmatter.publish_date} />
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </BlogContentBox>
        </Section>
      </Container>
      <Container>
        <Typography variant="h3" align="center">Related Posts</Typography>
        <Section tightTop noBottom> 
          <BlogList posts={relatedPosts} />
        </Section>
        <Section tightBottom>
          <Box display="flex" justifyContent="center">
            <Button color="primary" size="large" component={Link} to={"/blog/"}>
              <FontAwesomeIcon icon={faLongArrowAltLeft} />&nbsp; Back To Blog
                </Button>
          </Box>
        </Section>
      </Container>
    </MainLayout>
  )
}

export default BlogPost;