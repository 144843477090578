import React, { FunctionComponent } from "react";
import { useTheme, Theme, Container } from "@material-ui/core";
import BackgroundImage from "gatsby-background-image";
import { BlogContentBox, TextOverlayType } from "./styledComponents";
import { getBackgroundImageStack, HeroImage } from "./hero";

interface BlogHeroProps {
    image: import("gatsby-source-filesystem").FileSystemNode;
    title: string,
}

const BlogHero : FunctionComponent<BlogHeroProps> = (props) => {
    const theme = useTheme<Theme>();

    return (
        <>
        <HeroImage fluid={getBackgroundImageStack((props.image.childImageSharp as any).fluid, theme.palette.primary.dark)} Tag="section">
            <Container maxWidth="md">
                <BlogContentBox theme={theme} display="flex" alignItems="center" height="65vh" minHeight={450} py={8}>
                    <TextOverlayType shadowColor={theme.palette.primary.dark} variant="h1" align="left">{props.title}</TextOverlayType>
                </BlogContentBox>
            </Container>
        </HeroImage>
        </>
    );
}

export default BlogHero;